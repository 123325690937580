var allProjects = [
  
  // {
  //   thumbnailUrl: "/physical/sephora-featured.png",
  //   path: "/project/sephora/",
  //   client: "Sephora x Kohl's",
  //   projectName: "A-Maze-ing Beauty Adventure",
  //   type: "physical",
  //   // featuredImageUrl: "/project/race-to-pcas/race-to-pcas_featured.png",
  //   description:
  //     "To celebrate the launch of Sephora at Kohl’s rolling out to more than 200 stores nationwide, TH Experiential created a mobile-first in-person activation; Sephora’s first since the onset of COVID in 2019. Using the new future of mobile integration as an opportunity, we reimagined the landscape of brand activations by developing a beauty maze that offered a unique, mobile-first experience, allowing guests to navigate hands-free for a touchless and contactless experience—naturally enforcing safe crowd control distancing.<p>Influencers, press, and consumers, were pinged with push notifications via geo-location beacon technology throughout the maze alerting them to interact, engage, create content, meet beauty brand founders and request songs from the DJ. The maze experience was also gamified, inviting guests to explore and discover eight brand and product touchpoints where they could scan and \"collect\" QR codes with their mobile devices and redeem for spin the wheel prizes in store.</p>",
  //   gallery: [
  //     {
  //       url: "https://player.vimeo.com/video/655570883?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
  //       type: "vimeo",
  //       alt: "",
  //       sizeClass: "full",
  //     },
  //     {
  //       url: "/project/sephora/sephora_2.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/sephora/sephora_3.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/sephora/sephora_4.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/sephora/sephora_5.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/sephora/sephora_6.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/sephora/sephora_7.png",
  //       alt: "",
  //       sizeClass: "twothird",
  //     },
  //     {
  //       url: "/project/sephora/sephora_8.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/sephora/sephora_9.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/sephora/sephora_10.png",
  //       alt: "",
  //       sizeClass: "twothird",
  //     },
  //     {
  //       url: "/project/sephora/sephora_11.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/sephora/sephora_12.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //   ],
  // },
  // {
  //   thumbnailUrl: "/physical/25.png",
  //   path: "/project/you-wish-vending-machine/",
  //   client: "Maker's Mark",
  //   projectName: '"You Wish" Vending Machine',
  //   type: "physical",
  //   // featuredImageUrl: "/project/road-to-discovery/road-to-discovery_featured.png",
  //   description:
  //     "On behalf of Maker’s Mark, TH design, fabricated, permitted and produced the “You Wish” campaign, consisting of a mock bourbon vending machine being placed at unsuspecting locales in 12 markets across the country. The 10’ dual-sided, illuminated installation was wrapped in Maker’s Mark creative displaying the clever tag line “You Wish,” playing on the farfetched notion that whiskey lovers could purchase their favorite liquor brand, Maker’s Mark, via the convenience of a vending machine. With TH overseeing all permitting and on-site management, the campaign ran over the course of seven months, popping up at key high traffic locations, such as sporting and music events, including SXSW in Austin and Wrigley Field in Chicago.<br />*Obie Award Winner",
  //   statBar: [
  //     {
  //       statNumber: "12",
  //       statName: "activation days",
  //     },
  //     {
  //       statNumber: "800,000",
  //       statName: "visual impressions",
  //     },
  //   ],
  //   gallery: [
  //     {
  //       url: "/project/you-wish-vending/you-wish-vending_1.png",
  //       alt: "",
  //       sizeClass: "full",
  //     },
  //     {
  //       url: "/project/you-wish-vending/you-wish-vending_2.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/you-wish-vending/you-wish-vending_3.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/you-wish-vending/you-wish-vending_4.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/you-wish-vending/you-wish-vending_5.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/you-wish-vending/you-wish-vending_6.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/you-wish-vending/you-wish-vending_7.png",
  //       alt: "",
  //       sizeClass: "twothird",
  //     },
  //     {
  //       url: "/project/you-wish-vending/you-wish-vending_8.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //   ],
  // },
  // {
  //   thumbnailUrl: "/physical/26.jpg",
  //   path: "/project/reba/",
  //   client: "Drift",
  //   projectName: "Reba",
  //   type: "physical",
  //   // featuredImageUrl: "/project/road-to-discovery/road-to-discovery_featured.png",
  //   description:
  //     "To help Drift energy on their mission to increase awareness and education of unique energy solutions, we brought green energy sources to life in a way guests could not only understand, but relate to personally at the REBA (Renewable Energy Buyers Alliance) conference. TH designed and developed an interactive, large-scale LED wall installation leveraging Kinect motion technology that allowed attendees to discover Drift’s 100% green and instantaneous energy offerings. Through motion attendees were empowered to animate an electron representing wind, solar or hydro clean energy sources. An adjoining digital touch screen experience gave guests control of the electron as they watched it react with intense, dynamic particle effects.",
  //   gallery: [
  //     {
  //       url: "/project/reba/drift-reba_1.jpg",
  //       alt: "",
  //       sizeClass: "full",
  //     },
  //     {
  //       url: "/project/reba/drift-reba_2.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/reba/drift-reba_3.jpg",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/reba/drift-reba_4.jpg",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/reba/drift-reba_5.jpg",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/reba/drift-reba_6.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/reba/drift-reba_7.jpg",
  //       alt: "",
  //       sizeClass: "twothird",
  //     },
  //     {
  //       url: "/project/reba/drift-reba_8.jpg",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/reba/drift-reba_9.jpg",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/reba/drift-reba_10.jpg",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/reba/drift-reba_11.jpg",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/reba/drift-reba_12.jpg",
  //       alt: "",
  //       sizeClass: "twothird",
  //     },
  //   ],
  // },
  // {
  //   thumbnailUrl: "/physical/27.png",
  //   path: "/project/women-run-the-world/",
  //   client: "Shape",
  //   projectName: "Women Run the World",
  //   type: "physical",
  //   // featuredImageUrl: "/project/road-to-discovery/road-to-discovery_featured.png",
  //   description:
  //     'Shape Magazine wanted a partner who could bring their "Women Run the World" conference to life, capture the energy and spirit of their community and reflect it through decor, furnishings and visual design to further inspire and empower women. TH ignited the passion of attendees with a bold, vibrant event environment filled with bright colorful graphics and organic photo moments. The entire venue was transformed into an inspirational and empowering setting for the conference’s wide array of programming, which included panel talks, influencer appearances, workshops, yoga classes, massage sessions and more. Guests left feeling refreshed, invigorated and ready to turn their passion into purpose and change the world.',
  //   gallery: [
  //     {
  //       url: "/project/women-run-the-world/wrtw_1.png",
  //       alt: "",
  //       sizeClass: "full",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_2.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_3.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_4.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_5.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_6.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_7.png",
  //       alt: "",
  //       sizeClass: "twothird",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_8.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_9.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_10.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_11.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_12.png",
  //       alt: "",
  //       sizeClass: "twothird",
  //     },
  //   ],
  // },
  {
    thumbnailUrl: "/physical/33.png",
    path: "/project/misfits-paint-day/",
    client: "Misfits",
    projectName: "Paint Day",
    type: "physical",
    // featuredImageUrl: "/project/road-to-discovery/road-to-discovery_featured.png",
    description:
      'Misfits Gaming Paint Day took Twitch creators away from their computers and brought them to a real life gaming experience, and then streamed back into Twitch. We transformed a white space into Misfits Gaming Paint Day with high quality fabrication, lighting grids and an onsite broadcast engineering team with an onsite control room. The game began with the white canvas and ended hours later with a paint soaked highly entertaining experience that set a new standard of offline play for online players. This in-person gaming livestream event was the first of its kind within the gaming community as it allowed creators to participate in real life gaming that would usually take place virtually. This event got 490,000 eyes on the brand and doubled their Twitch subscribers in 3 hours.',
    gallery: [
      {
        url: "https://player.vimeo.com/video/822213804?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/misfits-paint-day/2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/misfits-paint-day/3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/misfits-paint-day/4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/misfits-paint-day/5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/misfits-paint-day/6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/misfits-paint-day/7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/misfits-paint-day/8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/misfits-paint-day/9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/misfits-paint-day/10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/misfits-paint-day/11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/misfits-paint-day/12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/32.png",
    path: "/project/homegoods-house-of-homegoods/",
    client: "HomeGoods",
    projectName: "House of HomeGoods",
    type: "physical",
    // featuredImageUrl: "/project/road-to-discovery/road-to-discovery_featured.png",
    description:
      'TH partnered with Edelman to bring to life the versatility of the HomeGoods brand through House of HomeGoods—an activation that highlighted immersive interiors and encouraged organic content creation. The activation featured a bookable getaway that offered consumers a chance to discover something new, as each visit was transformed into a new theme. For each theme, TH curated HomeGoods decor, wall treatments and furnishings inspiration showcasing interior design partners that would be a good fit for each genre. Every flip was distinct and different. The themes were completed with photo-worthy environments and unique activities that generated rich and compelling content for HomeGoods.',
    gallery: [
      {
        url: "/project/homegoods-house-of-homegoods/1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/homegoods-house-of-homegoods/2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/homegoods-house-of-homegoods/3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/homegoods-house-of-homegoods/4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/homegoods-house-of-homegoods/5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/homegoods-house-of-homegoods/6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/homegoods-house-of-homegoods/7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/homegoods-house-of-homegoods/8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/homegoods-house-of-homegoods/9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/homegoods-house-of-homegoods/10.png",
        alt: "",
        sizeClass: "half",
      }
    ],
  },
  {
    thumbnailUrl: "/physical/31.png",
    path: "/project/kohls-build-a-list/",
    client: "Kohl's",
    projectName: "Build-A-List",
    type: "physical",
    // featuredImageUrl: "/project/road-to-discovery/road-to-discovery_featured.png",
    description:
      'We partnered with Kohl’s on a 360-online to offline campaign featuring a custom digital experience and an in person pop-up. Kohl’s Build-A-List microsite enabled consumers to curate and personalize their Holiday Wishlist to share with friends, family and on social media by tagging @Kohls and #BuildAListSweepstakes for a chance to win $250 Kohl’s Cash.<br/><br/>This 360 digital activation includes organic social, paid social, influencer marketing, email, SMS, ecommerce and in-person events for maximum reach and results.',
    gallery: [
      {
        url: "/project/kohls-build-a-list/1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/kohls-build-a-list/2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/kohls-build-a-list/3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/kohls-build-a-list/4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/kohls-build-a-list/5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/kohls-build-a-list/6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/kohls-build-a-list/7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/kohls-build-a-list/8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/kohls-build-a-list/9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/kohls-build-a-list/10.png",
        alt: "",
        sizeClass: "half",
      }
    ],
  },
  {
    thumbnailUrl: "/physical/30.png",
    path: "/project/farmacy-farmacyland/",
    client: "Farmacy",
    projectName: "Farmacyland Popup",
    type: "physical",
    // featuredImageUrl: "/project/road-to-discovery/road-to-discovery_featured.png",
    description:
      'For the D2C brand, Farmacy Beauty, we designed and built their first ever pop-up—FARMACYLAND since their launch seven years ago. We designed an environment and experience that brought to life the brand’s commitment to clean beauty, sustainability, and philanthropy: fabricating with 83% upcycled materials and donating five meals to Feeding America for every guest in attendance.',
    gallery: [
      {
        url: "https://player.vimeo.com/video/822214371?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/farmacy-farmacyland/2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/farmacy-farmacyland/3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/farmacy-farmacyland/4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/farmacy-farmacyland/5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/farmacy-farmacyland/6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/farmacy-farmacyland/7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/farmacy-farmacyland/8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/farmacy-farmacyland/9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/farmacy-farmacyland/10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/farmacy-farmacyland/11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/farmacy-farmacyland/12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/29.png",
    path: "/project/cycle-house/",
    client: "Rael",
    projectName: "Cycle House",
    type: "physical",
    // featuredImageUrl: "/project/road-to-discovery/road-to-discovery_featured.png",
    description:
      'TH partnered with Real Beauty to turn one of the most exclusive homes in Bel-Air into RAEL’s Cycle House— a highly immersive content creation experience where influencers learned about the products while creating custom Instagram/TikTok worthy content at every touch point.<br/><br/>Rael Cycle House incorporated the four stages of the menstrual cycle through health and wellness activities further amplifying RAEL’s brand messaging and storytelling. This activation resulted in 12.7M impressions for the brand.',
    gallery: [
      {
        url: "/project/rael-cycle-house/1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/rael-cycle-house/2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/rael-cycle-house/3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/rael-cycle-house/4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/rael-cycle-house/5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/rael-cycle-house/6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/rael-cycle-house/7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/rael-cycle-house/8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/rael-cycle-house/9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/rael-cycle-house/10.png",
        alt: "",
        sizeClass: "half",
      }
    ],
  },
  {
    thumbnailUrl: "/physical/28.png",
    path: "/project/lipstick-lounge/",
    client: "Rose Inc",
    projectName: "Lipstick Lounge",
    type: "physical",
    // featuredImageUrl: "/project/road-to-discovery/road-to-discovery_featured.png",
    description:
      'We recently partnered with Rosie Huntington Whitley’s newly launched beauty line, Rose Inc, to create a novel pop-up experience in NYC. Guests were able to check in with their mobile phone, select what experiences and services they were interested in and book them. This strategy engaged 87% of attendees and 70% participated in one-on-one makeup services. <br /><br />We leveraged interactive technology experiences to immerse guests in the brand campaign and messaging. The pop-up entrance featured a reactive LED floor that revealed all of the newly launching lipstick shade names as people walked over. It provided an organic photo moment. They could also access augmented reality filters that surprised them with a lipstick try on moment and fun campaign branded overlays. People who posted their content to the hashtag were featured on a live UGC digital display wall onsite.',
    gallery: [
      {
        url: "https://player.vimeo.com/video/822214470?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/roseinc-lipstick-lounge/2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/roseinc-lipstick-lounge/3.jpg",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/roseinc-lipstick-lounge/4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/roseinc-lipstick-lounge/5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/roseinc-lipstick-lounge/6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/roseinc-lipstick-lounge/7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/roseinc-lipstick-lounge/8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/roseinc-lipstick-lounge/9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/roseinc-lipstick-lounge/10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/roseinc-lipstick-lounge/11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/roseinc-lipstick-lounge/12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  // {
  //   thumbnailUrl: "/physical/27.png",
  //   path: "/project/women-run-the-world/",
  //   client: "Shape",
  //   projectName: "Women Run the World",
  //   type: "physical",
  //   // featuredImageUrl: "/project/road-to-discovery/road-to-discovery_featured.png",
  //   description:
  //     'Shape Magazine wanted a partner who could bring their "Women Run the World" conference to life, capture the energy and spirit of their community and reflect it through decor, furnishings and visual design to further inspire and empower women. TH ignited the passion of attendees with a bold, vibrant event environment filled with bright colorful graphics and organic photo moments. The entire venue was transformed into an inspirational and empowering setting for the conference’s wide array of programming, which included panel talks, influencer appearances, workshops, yoga classes, massage sessions and more. Guests left feeling refreshed, invigorated and ready to turn their passion into purpose and change the world.',
  //   gallery: [
  //     {
  //       url: "/project/women-run-the-world/wrtw_1.png",
  //       alt: "",
  //       sizeClass: "full",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_2.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_3.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_4.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_5.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_6.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_7.png",
  //       alt: "",
  //       sizeClass: "twothird",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_8.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_9.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_10.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_11.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/women-run-the-world/wrtw_12.png",
  //       alt: "",
  //       sizeClass: "twothird",
  //     },
  //   ],
  // },
  {
    thumbnailUrl: "/digital/Microsite_Desktop_featured.png",
    path: "/project/anthos-agm2022/",
    client: "Anthos",
    projectName: "AGM 2022",
    type: "digital",
    // featuredImageUrl: "/project/wellness-experience/monat_wellness-experience_featured.png",
    description:
      "TH partnered with Anthos to bring their AGM 2022 conference to life online. The event included discussions by Anthos leadership, reviews of the market, trends shaping the future, and Anthos performance and portfolio. Attendees received branded e-vites and had the opportunity to attend via a dedicated microsite including a Q&A, polling, and live replays. We leveraged in-event motion graphics and AV broadcasting to ensure a flawless execution.",
    gallery: [
      // {
      //   url: "/project/wellness-experience/monat_wellness-experience_1.gif",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/822214913?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      }
    ],
  },
  {
    thumbnailUrl: "/digital/TOPPS_featured_web.jpg",
    path: "/project/topps-digicon2022/",
    client: "Topps",
    projectName: "Digicon 2022",
    type: "digital",
    // featuredImageUrl: "/project/wellness-experience/monat_wellness-experience_featured.png",
    description:
      "We partnered with Topps to produce Topp’s Digicon 2022 - an annual virtual celebration that puts a spotlight on the growing global collecting community. To engage with their fans and grow their collector community following, we created a visually unique experience featuring brand new in app content releases, watch parties, interviews with celebrities, personalities, athletes, artists & app events with notable brands like Disney, Marvel, etc. We were able to successfully stream via 2 different platforms expanding Topps reach from previous years. This resulted in higher viewership, engagement and social followers across both Twitch and Twitter where their audiences are.",
    gallery: [
      // {
      //   url: "/project/wellness-experience/monat_wellness-experience_1.gif",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/822211617?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      }
    ],
  },
  {
    thumbnailUrl: "/digital/LRP_Featured_web.jpg",
    path: "/project/lrp-feud/",
    client: "LRP",
    projectName: "Family Feud",
    type: "digital",
    // featuredImageUrl: "/project/wellness-experience/monat_wellness-experience_featured.png",
    description:
      "We partnered with La Roche-Posay to create a virtual gaming experience on Zoom that sparked conversation around skincare and educated and entertained those who attended. The event format was based on the popular TV game show, Family Feud, and leveraged AV broadcasting and motion graphic innovation to stream into the webinar platform which resembled a microsite experience. The game included three rounds of questions and a “fast money” round hosted by a famous dermatologist to further promote brand messaging and storytelling.",
    gallery: [
      // {
      //   url: "/project/wellness-experience/monat_wellness-experience_1.gif",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/822215003?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      }
    ],
  },
  {
    thumbnailUrl: "/digital/12.png",
    path: "/project/pink-pony-virtual-floral-workshop/",
    client: "Ralph Lauren",
    projectName: "Pink Pony Virtual Floral Workshop",
    type: "digital",
    // featuredImageUrl: "/project/pink-pony-virtual-floral-workshop/rl_pink-pony_featured.png",
    description:
      "Ralph Lauren Fragrances partnered with us to develop their first ever digital event to drive awareness for their philanthropic work fighting breast cancer. Their objective was for the press and media to experience a new fragrance launch whose sales proceeds are 100% donated to the RL Pink Pony fund. We designed and executed a virtual floral arranging workshop with celebrity florist, Lewis Miller. Attendees were shipped florals that represented the top notes of the fragrance including the tools required to participate. Lewis Miller was streamed in live from his studio and led the workshop allowing attendees to virtually follow along and create their own arrangements. In addition to this interactive experience, we streamed in brand representatives to educate on the initiative and layered in campaign content to enrich the storytelling. We leveraged the event platforms interactive features to keep the audience engaged and concluded with a live audio Q&A.",
    gallery: [
      // {
      //   url: "/project/pink-pony-virtual-floral-workshop/rl_pink-pony_1.gif",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/612353719?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/pink-pony-virtual-floral-workshop/rl_pink-pony_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/pink-pony-virtual-floral-workshop/rl_pink-pony_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/pink-pony-virtual-floral-workshop/rl_pink-pony_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/pink-pony-virtual-floral-workshop/rl_pink-pony_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/pink-pony-virtual-floral-workshop/rl_pink-pony_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/pink-pony-virtual-floral-workshop/rl_pink-pony_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/pink-pony-virtual-floral-workshop/rl_pink-pony_8.png",
        alt: "",
        sizeClass: "onethird",
      },
    ],
  },
  {
    thumbnailUrl: "/digital/Cedar_featured.png",
    path: "/project/cedar-summit/",
    client: "Cedar",
    projectName: "Summit",
    type: "digital",
    // featuredImageUrl: "/project/wellness-experience/monat_wellness-experience_featured.png",
    description:
      "We collaborated with Cedar Medical to create a B2B digital event where virtual attendees could experience a 3D immersive environment similar to the venue where the brand usually hosts their in-person conference. Guests navigated in first person throughout the space and not only watched 25 live speaker streamed presentations, but also joined breakout sessions, engaged in trivia gameplay, live chat, signed up for virtual activities, and socialized live over cocktails. This event saw high guest engagement with 62% participating in interactive activities.",
    gallery: [
      // {
      //   url: "/project/wellness-experience/monat_wellness-experience_1.gif",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/656430672?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_8.jpg",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/digital/7.png",
    path: "/project/wellness-experience/",
    client: "Monat",
    projectName: "Wellness Experience",
    type: "digital",
    // featuredImageUrl: "/project/wellness-experience/monat_wellness-experience_featured.png",
    description:
      "We partnered with MONAT Beauty to celebrate their brand culture and launch their new Wellness line to their 40,000+ worldwide selling partners and VIPs. The brand understood the need to meet their community in the virtual space and the importance of delivering interactive and innovative digital experiences that would surprise their audience with engagement beyond just passively watching content. To deliver on their ask, we pushed digital experiential boundaries and developed a 100% custom and unique digital event including content creation, AR product animations, a digital game arcade and virtual photo booth.",
    gallery: [
      // {
      //   url: "/project/wellness-experience/monat_wellness-experience_1.gif",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/612789560?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_8.jpg",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },{
    thumbnailUrl: "/digital/Investopedia_Featured.png",
    path: "/project/investopedia-money/",
    client: "Investopedia",
    projectName: "Your Money Your Health",
    type: "digital",
    // featuredImageUrl: "/project/wellness-experience/monat_wellness-experience_featured.png",
    description:
      "Investopedia’s Your Money, Your Health 2021 Virtual Conference invited attendees to explore the undeniable connection between financial wellness and overall health, particularly in the context of the COVID-19 pandemic, and through a series of informative panels led by industry experts.<p>The event included five panels moderated by Investopdia's Editor-in-Chief, Caleb Silver and where over 1,200 attendees were engaged with the live chat, polling, and Q&A interactive features. </p>",
    gallery: [
      // {
      //   url: "/project/wellness-experience/monat_wellness-experience_1.gif",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/656431174?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_8.jpg",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/wellness-experience/monat_wellness-experience_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/digital/9.png",
    path: "/project/game-day/",
    client: "Dexcom",
    projectName: "Game Day",
    type: "digital",
    // featuredImageUrl: "/project/game-day/dexcom_game-day_featured.png",
    description:
      "For the 2021 Super Bowl, we were challenged by diabetes technology leader, Dexcom, to develop a way to engage audiences around their first ever commercial and celebrity talent announcement, Nick Jonas. They wanted diabetics and football fans to be able to digitally connect with him. We leveraged innovative AR video technology to enable people to “virtually have Nick over for the Super Bowl” and create highly shareable animated content. Users were given the option between the two videos of Nick and were able to record themselves next to him in their homes. Thousands of Superbowl watchers participated in this novel interactive experience. Nick himself found value in the experience and was quoted in Adweek saying, “Anytime I can find a way to connect with my fans and beyond in this virtual world...I am excited by it.",
    gallery: [
      // {
      //   url: "/project/game-day/dexcom_game-day_1.gif",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/612353660?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/game-day/dexcom_game-day_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/game-day/dexcom_game-day_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/game-day/dexcom_game-day_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/game-day/dexcom_game-day_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/game-day/dexcom_game-day_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/game-day/dexcom_game-day_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/game-day/dexcom_game-day_8.png",
        alt: "",
        sizeClass: "onethird",
      },
    ],
  },
  {
    thumbnailUrl: "/digital/8.png",
    path: "/project/afropunk-festival/",
    client: "AFROPUNK",
    projectName: "Planet AFROPUNK Festival",
    type: "digital",
    // featuredImageUrl: "/project/afropunk-festival/afropunk_festival_featured.png",
    description:
      "Over the past few years the PLANET AFROPUNK Festival grew from a grassroots community movement to a powerful worldwide phenomenon. They recently partnered with Target in 2020 to take the event virtual and expand its global reach exponentially. As their partner we conceptualized and designed the AFROPUNK Ideaville festival destination where we live streamed 50+ musical artists, cultural icons, and thought leaders from all over the world. This digital experience was a first for both Target and AFROPUNK with over 15,000 attendees across four continents, all with rich engagement including surveys, live chat panel discussions with global influencers, accessing exclusive playlists, participating in self care sessions, and receiving discounts to purchase books from renowned Black authors.",
    gallery: [
      // {
      //   url: "/project/afropunk-festival/afropunk_festival_1.gif",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/612334989?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/afropunk-festival/afropunk_festival_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/afropunk-festival/afropunk_festival_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/afropunk-festival/afropunk_festival_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/afropunk-festival/afropunk_festival_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/afropunk-festival/afropunk_festival_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/afropunk-festival/afropunk_festival_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/afropunk-festival/afropunk_festival_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/afropunk-festival/afropunk_festival_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/afropunk-festival/afropunk_festival_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/afropunk-festival/afropunk_festival_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/afropunk-festival/afropunk_festival_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/digital/3.png",
    path: "/project/farmacy/",
    client: "Farmacy Beauty",
    projectName: "Virtual Product Launch",
    type: "digital",
    // featuredImageUrl: "/project/farmacy-launch/farmacy_launch_featured.png",
    description:
      "We designed a custom site for Farmacy’s Spring Product Launch where we took press and influencers on a journey where they not only learned about the new products, but also: <ul><li>Networked with each other and brand representatives in the live chat</li><li>Played a custom developed game simulating the brand’s lab, and where guests assembled the product ingredients and learn about their unique properties</li><li>Points earned from gameplay triggered donations to the charity Feeding America</li><li>Attendees were invited to personalize launch products with custom messages and label designs to send to friends</li></ul><p>This event generated over 78 stories and over $35K in EMV.</p>",
    gallery: [
      // {
      //   url: "/project/farmacy-launch/farmacy_launch_1.png",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/612353672?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/farmacy-launch/farmacy_launch_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/farmacy-launch/farmacy_launch_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/farmacy-launch/farmacy_launch_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/farmacy-launch/farmacy_launch_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/farmacy-launch/farmacy_launch_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/farmacy-launch/farmacy_launch_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/farmacy-launch/farmacy_launch_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/farmacy-launch/farmacy_launch_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/farmacy-launch/farmacy_launch_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/farmacy-launch/farmacy_launch_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/farmacy-launch/farmacy_launch_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/digital/1.png",
    path: "/project/virtual-press-event/",
    client: "Valentino Beauty",
    projectName: "Virtual Press & Influencer Event",
    type: "digital",
    // featuredImageUrl: "/project/virtual-press-event/valentino_featured.png",
    description:
      "We partnered with Valentino Beauty to launch the brand with a virtual press event. We filmed in real life at Root Studios in Brooklyn, and streamed live to over 200 press and influencers. We found that 52% of people are interested in virtual events and this approach contributed to a successful 71% RSVP attendance and over 28 million social impressions. Over half of the press and media attendees participated in the live chat and shared feedback on the artistry and products, many of them having the opportunity to trial the products in advance with their seeding kits. The event included a consumer extension on YouTube with integrated ecommerce and got the brand 61,000 impressions on the newly launched YouTube channel.",
    gallery: [
      // {
      //   url: "/project/virtual-press-event/valentino_1.png",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/655534073?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/virtual-press-event/valentino_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/virtual-press-event/valentino_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/virtual-press-event/valentino_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/virtual-press-event/valentino_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/virtual-press-event/valentino_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/virtual-press-event/valentino_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/virtual-press-event/valentino_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/virtual-press-event/valentino_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/virtual-press-event/valentino_10.png",
        alt: "",
        sizeClass: "half",
      },
    ],
  },
  {
    thumbnailUrl: "/digital/10.png",
    path: "/project/carries-winter-wonderland/",
    client: "Universal Music",
    projectName: "Carrie's Winter Wonderland",
    type: "digital",
    // featuredImageUrl: "/project/carries-winter-wonderland/carries-winter-wonderland_featured.png",
    description:
      "We were tapped by Universal Music and Carrie Underwood to create a Winter Wonderland digital event in support of her My Gift holiday album launch and featuring unique content and experiences for her community. Carrie’s commitment to her fans and desire to connect with them really demonstrates the power of what virtual events deliver. We brought her My Gift album cover to life in this digital space and filled the event with exclusive messages, AR experiences, gamified giveaways and curated content from Carrie herself. We also developed a strategic traffic driving social campaign which Carrie launched on her channels, getting over 200K+ views and driving thousands to the event in its first few days.",
    gallery: [
      // {
      //   url: "/project/carries-winter-wonderland/carries-winter-wonderland_1.gif",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/612353616?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_9.gif",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_11.gif",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/digital/Sprouts_Featured.png",
    path: "/project/sprouts-wellness-livestream/",
    client: "Sprouts",
    projectName: "Wellness Livestream",
    type: "digital",
    // featuredImageUrl: "/project/carries-winter-wonderland/carries-winter-wonderland_featured.png",
    description:
      "TH teamed up with Sprouts to host a branded virtual health panel: Natural Remedies for Anxiety, Inflammation, and Immunity. Celebrity host Maria Menounos was joined by a panel of wellness experts who shared their knowledge and personal tips & tricks on a variety of health-related topics. <p>To encourage audience participation throughout the event, attendees were invited to submit questions in the chat to be entered into a contest to win wellness bundles from Ancient Nutrition and The One, or a $2,500 Instacart gift card. With 2,656 in attendance, we saw an 86% retention rate, with 65% of attendees engaging in the chat.</p>",
    gallery: [
      // {
      //   url: "/project/carries-winter-wonderland/carries-winter-wonderland_1.gif",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/656431159?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_9.gif",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_11.gif",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/digital/Vevo_Featured.png",
    path: "/project/vevo-webinars/",
    client: "Vevo",
    projectName: "Webinars",
    type: "digital",
    // featuredImageUrl: "/project/carries-winter-wonderland/carries-winter-wonderland_featured.png",
    description:
      "TH teamed up with Vevo to host a four-part series of branded educational webinar events designed to help marketers navigate an ever-changing media landscape. Sessions focused on best practices for activating on CTV, how to develop authentic connections with diverse audiences and how marketers can efficiently reach their target consumers in the quickly evolving local marketplace.<p>Included in each of the events was a live Q&A component to enhance attendee engagement and education. We also featured a live chat so that the audience could interact with Vevo brand executives and each other throughout the experience. This branded and highly engaging webinar format resulted in an average 72% retention rate among 400 attendees across the four events.</p>",
    gallery: [
      // {
      //   url: "/project/carries-winter-wonderland/carries-winter-wonderland_1.gif",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/656431146?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_9.gif",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_11.gif",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/carries-winter-wonderland/carries-winter-wonderland_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/digital/11.png",
    path: "/project/inthegirlsroom/",
    client: "Olly",
    projectName: "#INTHEGIRLSROOM",
    type: "digital",
    // featuredImageUrl: "/project/inthegirlsroom/olly_inthegirlsroom_featured.png",
    description:
      "The Olly brand came to us to create an intimate virtual press and media experience for their women’s wellness product launch. We leveraged one of our virtual event platforms with a ‘watch together” feature where attendees could see and speak to each other live, just as they would in real life and hold audio conversations with the six person panel. To animate the theme of “safe space stories,” we fully branded the platform and developed gamified interactivity with fill in the blank storytelling and wellness trivia. This engaging digital experience was supported with physical at home kits facilitating 2-way participation. The combination of strong creative branding, interactivity, dialogue, and engaging content streaming resulted in the audience staying on for the entire duration of the event with zero drop off.",
    gallery: [
      {
        url: "/project/inthegirlsroom/olly_inthegirlsroom_1.gif",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/inthegirlsroom/olly_inthegirlsroom_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/inthegirlsroom/olly_inthegirlsroom_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/inthegirlsroom/olly_inthegirlsroom_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/inthegirlsroom/olly_inthegirlsroom_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/inthegirlsroom/olly_inthegirlsroom_7.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/inthegirlsroom/olly_inthegirlsroom_8.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/inthegirlsroom/olly_inthegirlsroom_9.png",
        alt: "",
        sizeClass: "onethird",
      },
    ],
  },
  {
    thumbnailUrl: "/digital/15.png",
    path: "/project/holiday-pj-party/",
    client: "Carter's",
    projectName: "Holiday Party",
    type: "digital",
    // featuredImageUrl: "/project/holiday-pj-party/holiday-pj-party_featured.png",
    description:
      "For the 2020 Holiday season, Carter’s collaborated with us to create a series of digital events empowering parents to refocus on the true meaning of the holidays and reinforce the notion that real joy is being in the company of your family and sharing in nostalgic activities. We leveraged our virtual event platform to bring to life two fully branded influencer led Holiday virtual events: a PJ Party with Leslie Odom and his family and a Baking Party with Whitney Port and her family. Attending consumer families could not only bake-along and read-along, but also enjoyed trivia games, surveys and live Q&A sessions with these celebrity families.",

    gallery: [
      {
        url: "/project/holiday-pj-party/holiday-pj-party_1.gif",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/holiday-pj-party/holiday-pj-party_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/holiday-pj-party/holiday-pj-party_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/holiday-pj-party/holiday-pj-party_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/holiday-pj-party/holiday-pj-party_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/holiday-pj-party/holiday-pj-party_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/holiday-pj-party/holiday-pj-party_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/holiday-pj-party/holiday-pj-party_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/holiday-pj-party/holiday-pj-party_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/holiday-pj-party/holiday-pj-party_10.png",
        alt: "",
        sizeClass: "half",
      },
    ],
  },
  {
    thumbnailUrl: "/digital/14.png",
    path: "/project/derm-jeopardy/",
    client: "La Roche Posay",
    projectName: "Derm Jeopardy",
    type: "digital",
    // featuredImageUrl: "/project/derm-jeopardy/derm-jeopardy_featured.png",
    description:
      "TH partnered with La Roche-Posay to create an elevated branded virtual gaming experience on the Zoom platform that sparked conversation around skincare and both educated and entertained attending editors. The event format was based on the popular TV game show, Jeopardy, and delivered a moment that disrupted what attendees have been normally experiencing on Zoom by providing real surprise and delight. We leveraged AV broadcasting and motion graphic innovation to stream into the webinar platform what resembled like a microsite experience. Following the event, we received rave reviews from the editors on how fun the game was to play and what a departure from the typical Zoom experience the event was overall.",
    gallery: [
      // {
      //   url: "/project/derm-jeopardy/derm-jeopardy_1.gif",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/612353641?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/derm-jeopardy/derm-jeopardy_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/derm-jeopardy/derm-jeopardy_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/derm-jeopardy/derm-jeopardy_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/derm-jeopardy/derm-jeopardy_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/derm-jeopardy/derm-jeopardy_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/derm-jeopardy/derm-jeopardy_7.gif",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/derm-jeopardy/derm-jeopardy_8.png",
        alt: "",
        sizeClass: "onethird",
      },
    ],
  },
  {
    thumbnailUrl: "/digital/13.png",
    path: "/project/escape-from-kidney-island/",
    client: "Bayer",
    projectName: "Escape From Kidney Island",
    type: "digital",
    // featuredImageUrl: "/project/kidney-island/bayer_kidney-island_featured.png",
    description:
      "To engage Bayer Pharmaceuticals conference attendees around the launch of their groundbreaking new kidney drug, we created an interactive and educational game that generated awareness in both a fun and innovative way. The “Escape Kidney Island” game allowed players to select an avatar and earn points by answering a series of questions developed to educate them on both the dangers of kidney disease and the life saving possibilities of this new drug. The gamified experience was well received and leveraged to create an internal tournament and then rolled out to the global Bayer community of 100,000 employees.",
    gallery: [
      // {
      //   url: "/project/kidney-island/bayer_kidney-island_1.gif",
      //   alt: "",
      //   sizeClass: "full",
      // },
      {
        url: "https://player.vimeo.com/video/612353705?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/kidney-island/bayer_kidney-island_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/kidney-island/bayer_kidney-island_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/kidney-island/bayer_kidney-island_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/kidney-island/bayer_kidney-island_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/kidney-island/bayer_kidney-island_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/kidney-island/bayer_kidney-island_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/kidney-island/bayer_kidney-island_8.png",
        alt: "",
        sizeClass: "onethird",
      },
    ],
  },
  {
    thumbnailUrl: "/hybrid/feature-uno.png",
    path: "/project/uno-championship/",
    client: "Uno",
    projectName: "Championship",
    type: "hybrid",
    description:
      "We held the first ever UNO card game tournament for Mattel with a Covid-safe 150 person live event in Las Vegas to celebrate the brand's 50th anniversary. We strategized a hybrid approach to deliver the most accessible and inclusive experience for their global audience. The tournament hosted by eSports gaming celebrity Ninja, and was streamed live on the brand’s newly launched TikTok channel and viewed by over 460,000 fans globally.",
    gallery: [
      {
        url: "https://player.vimeo.com/video/655533034?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/uno-championship/uno-2.png",
        alt: "",
        subtitle: "In-Person",
        sizeClass: "half",
      },
      {
        url: "/project/uno-championship/uno-3.gif",
        alt: "",
        subtitle: "Virtual Extension on Tiktok",
        sizeClass: "half",
      },
    ],
  },
  {
    thumbnailUrl: "/hybrid/feature-cetaphil.png",
    path: "/project/cetaphil-relaunch/",
    client: "Cetaphil",
    projectName: "Relaunch",
    type: "hybrid",
    description:
      "To relaunch the Cetaphil brand, new logo and products, we hosted a smaller in-person press event and live streamed it to a nationwide media audience through a custom branded microsite. Both audiences participated in the live Q&A and three “lab breakouts” where they experienced live demonstrations of the science behind the product innovation.",
    gallery: [
      {
        url: "https://player.vimeo.com/video/655532980?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
        customVideoWidth: 1220,
        customVideoHeight: 720
      },
      {
        url: "/project/cetaphil-relaunch/cetaphil-2.png",
        alt: "",
        subtitle: "In-Person",
        sizeClass: "half",
      },
      {
        url: "/project/cetaphil-relaunch/cetaphil-3.png",
        alt: "",
        subtitle: "VIRTUAL EXTENSION ON CUSTOM MICROSITE",
        sizeClass: "half",
      }
    ],
  },
  {
    thumbnailUrl: "/hybrid/feature-prh.png",
    path: "/project/prh-obama/",
    client: "Penguin Random House",
    projectName: "Becoming: Michelle Obama",
    type: "hybrid",
    description:
      "TH collaborated with Penguin Random House and BET to broadcast the BECOMING Michelle Obama in Conversation hybrid event at Howard University. Moderated by Yara Shahidi, college students from the United States engaged in an open conversation with Mrs. Obama, having the opportunity to discuss topics that were most important to them. In addition to the in-person attendees, 4,923 students from around the country tuned into the event through a custom-branded digital site, allowing them to enjoy the experience live with Mrs. Obama.",
    gallery: [
      {
        url: "https://player.vimeo.com/video/655532855?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/prh-obama/prh-2.png",
        alt: "",
        subtitle: "In-Person",
        sizeClass: "half",
      },
      {
        url: "/project/prh-obama/prh-3.png",
        alt: "",
        subtitle: "VIRTUAL EXTENSION ON EVENT PLATFORM",
        sizeClass: "half",
      }
    ],
  },
  {
    thumbnailUrl: "/hybrid/feature-lush.png",
    path: "/project/lush-park/",
    client: "Lush",
    projectName: "In the Park",
    type: "hybrid",
    description:
      "TH teamed up with Lush Fresh Handmade Cosmetics to design and produce a unique moment to engage their community and new audiences around what they care about: insanely fresh ingredients and products. The brand’s behind-the-scenes chemists, a.k.a. Compounders, were brought to the forefront, demonstrating live how they make Lush products from citrus fruits, plants, grains and more. Oversized living walls covered in over twelve different plants including Asplenium, Japanese Birdsnest, Tri-color Oyster, Ferns and Ivy lead guests into the #LushInThePark interactive experience. Mounted touch screen monitors allowed people to select fragrance preferences and paired them with a Lush Compounder. At the Compounder Bar, guests’ product was handmade in front of them, a live storytelling moment on how truly “fresh” Lush’s products are.",
    gallery: [
      {
        url: "https://player.vimeo.com/video/655532771?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/lush-park/lush-2.png",
        alt: "",
        subtitle: "In-Person",
        sizeClass: "half",
      },
      {
        url: "/project/lush-park/lush-3.gif",
        alt: "",
        subtitle: "VIRTUAL EXTENSION ON YOUTUBE",
        sizeClass: "half",
      }
    ],
  },
  {
    thumbnailUrl: "/hybrid/feature-ebay.png",
    path: "/project/ebay-tokyo/",
    client: "Ebay",
    projectName: "Ebay x Toy Tokyo Event",
    type: "hybrid",
    description:
      "For Comic Con, we partnered with eBay to produce a hybrid event introducing their NYCC exclusive merch + cards to the brand's fans and media. Hosted by celebrity DJ Skee, the event created the hype for attendees as they watched the host unbox an exclusive Pokémon booster box. The event was simulcast to three social streams including ebay and DJ Skee’s Twitter as well as eBay’s Instagram page. This virtual extension increased the audience reach to an additional 2,500 viewers.",
    gallery: [
      {
        url: "/project/ebay-tokyo/ebay-1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/ebay-tokyo/ebay-2.png",
        alt: "",
        subtitle: "In-Person",
        sizeClass: "half",
      },
      {
        url: "/project/ebay-tokyo/ebay-3.gif",
        alt: "",
        subtitle: "VIRTUAL EXTENSION ON TIKTOK",
        sizeClass: "half",
      }
    ],
  },{
    thumbnailUrl: "/hybrid/feature-puma.png",
    path: "/project/puma-workout/",
    client: "Puma",
    projectName: "Workout with NYCB",
    type: "hybrid",
    description:
      "TH partnered with PUMA and New York City Ballet to create a one-of-a-kind workout experience for New Yorkers on September 23rd, 2017 in Lincoln Center Plaza in celebration of PUMA’s Velvet Rope Collection launch. PUMA Faster Trainers and New York City Ballet dancers choreographed and instructed a one hour, ballet inspired workout routine for 500 guests on the Plaza surrounding the iconic Revson Fountain. The event also featured exclusive PUMA and NYCB giveaways, a live DJ, and a VIP lounge for guests to enjoy prior to the workout. ",
    gallery: [
      {
        url: "https://player.vimeo.com/video/655532501?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/puma-workout/puma-2.png",
        alt: "",
        subtitle: "In-Person",
        sizeClass: "half",
      },
      {
        url: "/project/puma-workout/puma-3.gif",
        alt: "",
        subtitle: "Virtual extension on Facebook",
        sizeClass: "half",
      }
    ],
  },
  // {
  //   thumbnailUrl: "/physical/degree-featured.png",
  //   path: "/project/degree-trainers/",
  //   client: "Degree",
  //   projectName: "#TRAINERSFORHIRE Event",
  //   type: "physical",
  //   // featuredImageUrl: "/project/race-to-pcas/race-to-pcas_featured.png",
  //   description:
  //     "We collaborated with the Unilever Degree brand to create an inclusive pop-up spin class in the center of Flatiron Plaza, NYC. Ahead of National Disability Employment Awareness Month in October, Degree launched the ‘Trainers for Hire’ campaign—a call for more representation in the fitness industry by encouraging companies to hire adaptive trainers. This media activation engaged press and adaptve sports influencers around the need for increased access and inclusivity for disabled athletes.",
  //   gallery: [
  //     {
  //       url: "/project/degree-trainers/degree_1.png",
  //       alt: "",
  //       sizeClass: "full",
  //     },
  //     {
  //       url: "/project/degree-trainers/degree_2.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/degree-trainers/degree_3.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/degree-trainers/degree_4.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/degree-trainers/degree_5.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/degree-trainers/degree_6.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/degree-trainers/degree_7.png",
  //       alt: "",
  //       sizeClass: "twothird",
  //     },
  //     {
  //       url: "/project/degree-trainers/degree_8.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     }
  //   ],
  // },
  // {
  //   thumbnailUrl: "/physical/primark-featured.png",
  //   path: "/project/primark-american-dream/",
  //   client: "Primark",
  //   projectName: "American Dream Store Opening",
  //   type: "physical",
  //   // featuredImageUrl: "/project/race-to-pcas/race-to-pcas_featured.png",
  //   description:
  //     "Inspired by Primark’s iconic brown shopping  bag, we created a concept that maximizes this classic to celebrate the opening of the Primark store in the American Dream Mall. <p>We designed and built an oversized replica of the bag that functioned as an animated GIF photo booth.  Guests walked inside the bag and discovered a continuously changing colored interior video booth.</p>",
  //   gallery: [
  //     {
  //       url: "/project/primark-american-dream/primark_1.png",
  //       alt: "",
  //       sizeClass: "full",
  //     },
  //     {
  //       url: "/project/primark-american-dream/primark_2.png",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/primark-american-dream/primark_3.gif",
  //       alt: "",
  //       sizeClass: "half",
  //     },
  //     {
  //       url: "/project/primark-american-dream/primark_4.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/primark-american-dream/primark_5.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     },
  //     {
  //       url: "/project/primark-american-dream/primark_6.png",
  //       alt: "",
  //       sizeClass: "onethird",
  //     }
  //   ],
  // },
  {
    thumbnailUrl: "/physical/universal-pictures-355-featured.png",
    path: "/project/universal-pictures-355/",
    client: "Universal Pictures",
    projectName: "355 Movie Party",
    type: "physical",
    // featuredImageUrl: "/project/race-to-pcas/race-to-pcas_featured.png",
    description:
      "TH and Universal Pictures partnered to host the exclusive premiere party for the release of the female empowerment film, The 355, during Art Basel. Hosted at Soho House in Miami, this event featured several branded content creation moments, custom cocktails and sponsored giveaways in collaboration with Phenomenal.",
    gallery: [
      {
        url: "https://player.vimeo.com/video/655570871?h=ffb91ada95&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1&amp;autoplay=1&amp;muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/universal-pictures-355/universal355_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/universal-pictures-355/universal355_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/universal-pictures-355/universal355_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/universal-pictures-355/universal355_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/universal-pictures-355/universal355_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/universal-pictures-355/universal355_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/universal-pictures-355/universal355_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/universal-pictures-355/universal355_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/universal-pictures-355/universal355_10.png",
        alt: "",
        sizeClass: "half",
      }
    ],
  },
  {
    thumbnailUrl: "/physical/5.png",
    path: "/project/house-of-lg-signature/",
    client: "LG",
    projectName: "House of LG Signature",
    type: "physical",
    // featuredImageUrl: "/project/house-of-lg-signature/lg_house-of-lg-signature_featured.png",
    description:
      "The “House of LG Signature” was designed to introduce LG’s new Signature Series to a targeted audience of tastemakers and art enthusiasts during a highly-relevant conversational moment, Art Basel. Inviting guests to experience “The Art of Essence” they were guided through a series of immersive, breathtaking experiences designed in collaboration with four renowned artists, each working in a unique medium to highlight key attributes of items from the line. The end experience mirrored the brand’s mix of cutting edge technology and gorgeous aesthetics through a sensorial experience that left an indelible impression and generated word of mouth for the brand with a highly influential demographic.",
    statBar: [
      {
        statNumber: "1,800+",
        statName: "visitors",
      },
      {
        statNumber: "3",
        statName: "activation days",
      },
    ],
    gallery: [
      {
        url: "/project/house-of-lg-signature/lg_house-of-lg-signature_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/house-of-lg-signature/lg_house-of-lg-signature_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/house-of-lg-signature/lg_house-of-lg-signature_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/house-of-lg-signature/lg_house-of-lg-signature_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/house-of-lg-signature/lg_house-of-lg-signature_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/house-of-lg-signature/lg_house-of-lg-signature_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/house-of-lg-signature/lg_house-of-lg-signature_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/house-of-lg-signature/lg_house-of-lg-signature_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/house-of-lg-signature/lg_house-of-lg-signature_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/house-of-lg-signature/lg_house-of-lg-signature_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/house-of-lg-signature/lg_house-of-lg-signature_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/house-of-lg-signature/lg_house-of-lg-signature_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/ollyjb-featured.png",
    path: "/project/olly-jet-blue/",
    client: "Olly x Jet Blue",
    projectName: "Upgrade Your Sleep",
    type: "physical",
    // featuredImageUrl: "/project/race-to-pcas/race-to-pcas_featured.png",
    description:
      "The OLLY X JetBlue Upgrade Your Sleep pop-up attracted travelers at John F. Kennedy International Airport to an sleep themed immersive event. Guests explored OLLY branded air travel vignettes where they could learn about how to improve their sleep, discover OLLY supplements and create content. These experiences offered safe, mobile-first engagement with QR codes that launched Spin the Wheel gamification for prize winning or motion triggered AR photo moments. The activation drove an estimated 40,000 visual impressions over the course of 2 days.",
    gallery: [
      {
        url: "/project/olly-jet-blue/ollyjb_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/olly-jet-blue/ollyjb_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/olly-jet-blue/ollyjb_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/olly-jet-blue/ollyjb_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/olly-jet-blue/ollyjb_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/olly-jet-blue/ollyjb_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/olly-jet-blue/ollyjb_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/olly-jet-blue/ollyjb_8.gif",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/olly-jet-blue/ollyjb_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/olly-jet-blue/ollyjb_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/olly-jet-blue/ollyjb_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/olly-jet-blue/ollyjb_12.png",
        alt: "",
        sizeClass: "twothird",
      }
    ],
  },
  {
    thumbnailUrl: "/physical/4.png",
    path: "/project/the-game-of-maxximizing/",
    client: "T.J. Maxx",
    projectName: "The Game Of #Maxximizing",
    type: "physical",
    // featuredImageUrl: "/project/game-of-maxximizing/game-of-maxximizing_featured.png",
    description:
      "T.J. Maxx wanted to introduce a new term to retail - Maxximizing, to let consumers know they don’t have to sacrifice quality or price when it comes to finding their favorite items. To announce this high value approach to shopping, TH helped launch a multi-day, multi-city interactive game that gave customers a hands-on experience with getting more for their money than they may have thought possible, and in a fun way. Each game was housed in a photo-worthy pod and featured items curated by celebrity influencers and designers like Hannah Simone and Alli Webb centered around unique themes like “Maxximize Your Vacay” or “Maxximize Like a Boss.” To play onsite, participants could snap a picture and post with their guess of the dollar value to Instagram using the designated hashtags for a chance to win everything inside. Extending the contest beyond the footprint, consumers could also view the pod on the brand’s Instagram account as well as on partner influencers social accounts and offer their guesses in the comments section. T.J. Maxx announced a winner with the closest guess at the end of each day.",
    gallery: [
      {
        url: "/project/game-of-maxximizing/game-of-maxximizing_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/game-of-maxximizing/game-of-maxximizing_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/game-of-maxximizing/game-of-maxximizing_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/game-of-maxximizing/game-of-maxximizing_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/game-of-maxximizing/game-of-maxximizing_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/game-of-maxximizing/game-of-maxximizing_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/game-of-maxximizing/game-of-maxximizing_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/game-of-maxximizing/game-of-maxximizing_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/game-of-maxximizing/game-of-maxximizing_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/game-of-maxximizing/game-of-maxximizing_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/game-of-maxximizing/game-of-maxximizing_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/game-of-maxximizing/game-of-maxximizing_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/8.png",
    path: "/project/mist-you-madly/",
    client: "Soap & Glory",
    projectName: "Mist You Madly",
    type: "physical",
    // featuredImageUrl: "/project/mist-you-madly/mist-you-madly_featured.png",
    description:
      "TH teamed up with Soap & Glory to create an unforgettable launch event for press, media, and influencers inspired by the floral and seductive nature of the latest product line, Mist You Madly. At famed NYC burlesque club, Duane Park, guests were treated to exclusive live aerial entertainment from performers, product trials, light bites, and champagne toasts. Soap & Glory’s signature pink was integrated throughout all vignettes including a custom over-sized vanity, a custom champagne tap via a vintage sink, a luxe chaise lounge for guest photo ops, and a standalone tub to bring the at-home application environment to life. Beauty experts were on hand to talk guests through the product benefits and assist with application of the body lotions, body scrubs, and more.",
    gallery: [
      {
        url: "/project/mist-you-madly/mist-you-madly_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/mist-you-madly/mist-you-madly_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/mist-you-madly/mist-you-madly_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/mist-you-madly/mist-you-madly_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/mist-you-madly/mist-you-madly_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/mist-you-madly/mist-you-madly_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/mist-you-madly/mist-you-madly_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/mist-you-madly/mist-you-madly_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/mist-you-madly/mist-you-madly_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/mist-you-madly/mist-you-madly_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/mist-you-madly/mist-you-madly_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/mist-you-madly/mist-you-madly_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/9.png",
    path: "/project/stack-shack/",
    client: "Pringles",
    projectName: "Stack Shack",
    type: "physical",
    // featuredImageUrl: "/project/stack-shack/stack-shack_featured.png",
    description:
      "To create a moment where the Pringles brand and fans could unite to celebrate the iconic chip, TH designed and produced the “Stackateria,” a three-day pop-up inviting people to come in and “Get Stacking” and immerse themselves into a Pringle-filled world. The “Stackateria” popped up in Times Square with an oversized Pringles can as the entryway offering a surprise and delight moment and drawing in foot traffic. Once inside, guests could see, mix, taste, and try everything Pringles throughout the space and create their own Pringles stories: Tasting Station - Larger-than-life Pringles cans adorned the space where guests could sample their favorite, or entirely new, flavors. Create Your Own Stack - Guests were able to craft their very own custom concoction of flavors into a signature stack to take home. Blind Stack Challenge - Competing for a chance at a free Pringles shirt, consumers donned a branded blindfold and tested their flavor recognition skills. Social Photo Booth - A stylized version of the iconic New York City skyline offered a natural photo backdrop as guests posed with their stacks and created gifs for social sharing. Over 2,268 guests “Got Stacking” and created 2,504 custom Pringle stacks and over 900 brand stories shared on social.",
    gallery: [
      {
        url: "/project/stack-shack/stack-shack_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/stack-shack/stack-shack_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/stack-shack/stack-shack_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/stack-shack/stack-shack_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/stack-shack/stack-shack_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/stack-shack/stack-shack_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/stack-shack/stack-shack_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/stack-shack/stack-shack_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/stack-shack/stack-shack_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/stack-shack/stack-shack_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/stack-shack/stack-shack_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/stack-shack/stack-shack_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/1.png",
    path: "/project/e-network-race-to-pca/",
    client: "E!PCA",
    projectName: "Race to PCAs",
    type: "physical",
    // featuredImageUrl: "/project/race-to-pcas/race-to-pcas_featured.png",
    description:
      "NBC came to TH looking to ignite passions for pop culture across the U.S. for their inaugural broadcast of the People’s Choice Awards on E!. As the only award show that gives the power to the people, TH sought to celebrate each fan’s vote in a way that made it feel special and shareable. Inviting consumers to participate with fun messaging like “Voting Never Tasted So Good,” passersby were drawn in by a modern branded Airstream where they learn about the awards, voted for their favorite pop-culture icon and receive an Instagrammable edible gift. Each voter received a custom decorated donut packaged in E! PCA cartons. Cartons and voter pins were personalized with their choice of nominee. Consumers could share their voting experience on social and build even more support for their favorite celebrity or show. The physical engagement transformed voting into a memorable experience and incentivized consumers to tune in to the People’s Choice Awards live. In the end, TH took the experience to three markets and inspired more than 15,000 people to cote and generated over 2,000,000 million visual impressions for the award show.",
    gallery: [
      {
        url: "/project/race-to-pcas/race-to-pcas_1.jpg",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/race-to-pcas/race-to-pcas_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/race-to-pcas/race-to-pcas_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/race-to-pcas/race-to-pcas_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/race-to-pcas/race-to-pcas_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/race-to-pcas/race-to-pcas_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/race-to-pcas/race-to-pcas_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/race-to-pcas/race-to-pcas_8.jpg",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/race-to-pcas/race-to-pcas_9.jpg",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/race-to-pcas/race-to-pcas_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/race-to-pcas/race-to-pcas_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/race-to-pcas/race-to-pcas_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/11.png",
    path: "/project/in-the-park/",
    client: "Lush",
    projectName: "In the Park",
    type: "physical",
    // featuredImageUrl: "/project/lush-in-the-park/lush-in-the-park_featured.png",
    description:
      "TH teamed up with Lush Fresh Handmade Cosmetics to design and produce a unique moment to engage their community and new audiences around what they care about: insanely fresh ingredients and products. The brand’s behind-the-scenes chemists, a.k.a. Compounders, were brought to the forefront, demonstrating live how they make Lush products from citrus fruits, plants, grains and more. Oversized living walls covered in over twelve different plants including Asplenium, Japanese Birdsnest, Tri-color Oyster, Ferns and Ivy lead guests into the #LushInThePark interactive experience. Mounted touch screen monitors allowed people to select fragrance preferences and paired them with a Lush Compounder. At the Compounder Bar, guests’ product was handmade in front of them, a live storytelling moment on how truly “fresh” Lush’s products are.",
    statBar: [
      {
        statNumber: "3",
        statName: "activation days",
      },
      {
        statNumber: "150",
        statName: "attendees per hour",
      },
      {
        statNumber: "123,000+",
        statName: "Facebook impressions",
      },
      {
        statNumber: "2,916,245",
        statName: "live stream views",
      },
      {
        statNumber: "45,000",
        statName: "visual impressions",
      },
      {
        statNumber: "10,000",
        statName: "YouTube hits",
      },
    ],
    gallery: [
      {
        url: "/project/lush-in-the-park/lush-in-the-park_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/lush-in-the-park/lush-in-the-park_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/lush-in-the-park/lush-in-the-park_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/lush-in-the-park/lush-in-the-park_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/lush-in-the-park/lush-in-the-park_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/lush-in-the-park/lush-in-the-park_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/lush-in-the-park/lush-in-the-park_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/lush-in-the-park/lush-in-the-park_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/lush-in-the-park/lush-in-the-park_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/lush-in-the-park/lush-in-the-park_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/lush-in-the-park/lush-in-the-park_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/lush-in-the-park/lush-in-the-park_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/10.png",
    path: "/project/scents-of-self/",
    client: "Young Living",
    projectName: "Scents of Self",
    type: "physical",
    // featuredImageUrl: "/project/scents-of-self/scents-of-self_featured.png",
    description:
      "Like in fashion, wearing essential oils is an expression of your personality and mood. This is the philosophy of Young Living, and TH partnered with them to bring this idea to life for influencers, press and media during New York Fashion Week. Creating a multi-room, sensorial journey through a series of highly shareable spaces allowed guests to explore each scent, it’s “look,” and the emotions it inspires. Each person could individually connect with the product that best expressed their sense of self. Each of the four launching scents featured a unique experience: Lavender oil - an immersive, relaxing, meditative experience featuring 180 degree projections of lavender fields with a call to action to “Take A Breather.” Citrus oil - an interactive full-room citrus wallpaper that encouraged guests to “Be Bold” and add their own color with on-hand art supplies Rose oil - a celebration of empowerment, this room featured famous stories of influential women throughout history via a custom wall mural where guests could add their own motivational story. Peppermint oil - the journey ended with a peaceful peppermint inspired moment where guests were transported into a scenic woodsy photo moment and audio experience with the soothing sounds of nature. Rich in storytelling and filled with content creating moments, The Young Living Scents of Self experience generated over one billion earned media and social impressions.",
    gallery: [
      {
        url: "/project/scents-of-self/scents-of-self_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/scents-of-self/scents-of-self_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/scents-of-self/scents-of-self_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/scents-of-self/scents-of-self_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/scents-of-self/scents-of-self_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/scents-of-self/scents-of-self_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/scents-of-self/scents-of-self_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/scents-of-self/scents-of-self_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/scents-of-self/scents-of-self_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/scents-of-self/scents-of-self_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/scents-of-self/scents-of-self_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/scents-of-self/scents-of-self_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/13.png",
    path: "/project/pop-up-studio-apartment/",
    client: "Ikea",
    projectName: "Pop Up Apartment",
    type: "physical",
    // featuredImageUrl: "/project/ikea-pop-up/ikea-pop-up_featured.png",
    description:
      "For the grand opening of the Brooklyn IKEA store, TH partnered with Deutsch and IKEA to fabricate, produce and install a quintessential New York City studio apartment, fully outfitted in over 150 IKEA furniture pieces and accents. The 400-square-foot pop-up travelled throughout Manhattan and Brooklyn - Union Square, Borough Hall, the Brooklyn Public Library and Cadman Plaza - garnering buzz and awareness over the course of four consecutive days leading up to the store’s opening. TH's production team worked closely with IKEA's designers to ensure all program elements met the brand’s display standards while seamlessly installing the structure overnight at each location. As a result, the store opening saw over $1 million in sales with attendance exceeding 30,000.",
    statBar: [
      {
        statNumber: "4",
        statName: "activation days",
      },
      {
        statNumber: "569,969",
        statName: "visual impressions",
      },
      {
        statNumber: "30,000",
        statName: "shoppers during opening weekend",
      },
      {
        statNumber: "$1,000,000",
        statName: "in sales during opening weekend",
      },
    ],
    gallery: [
      {
        url: "/project/ikea-pop-up/ikea_popup-featured-lg.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/ikea-pop-up/ikea-pop-up_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/ikea-pop-up/ikea-pop-up_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/ikea-pop-up/ikea-pop-up_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/ikea-pop-up/ikea-pop-up_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/ikea-pop-up/ikea-pop-up_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/ikea-pop-up/ikea-pop-up_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/ikea-pop-up/ikea-pop-up_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/ikea-pop-up/ikea-pop-up_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/ikea-pop-up/ikea-pop-up_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/ikea-pop-up/ikea-pop-up_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/ikea-pop-up/ikea-pop-up_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/ikea-pop-up/ikea-pop-up_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/14.png",
    path: "/project/hidden-portraits/",
    client: "IBM x Watson",
    projectName: "Hidden Portraits",
    type: "physical",
    // featuredImageUrl: "/project/hidden-portraits/hidden-portraits_featured.png",
    description:
      "In a series of ongoing collaborative partnerships with IBM to showcase the amazing applications of the Watson supercomputer, TH brought to life the Cognitive Photobooth. Through a “conversation” with Watson, guests reveal aspects of their personality and experienced a unique photo moment where both AI and cognitive reasoning crafted participants’ portraits based on their individual facial features and traits. TH previewed this digital experience with a VIP and press event at The Cadillac House in New York, also providing cognitive-inspired bites and cocktails created from unique recipes in the IBM Cognitive Cookbook. The success and popularity of the experience led to a second, consumer-focused iteration at the U.S. open. For the high-attendance event, TH designed a seamless digital system where guests could sign up in advance for their AI-driven conversation and receive a custom branded printout of their analyzed photo at the end.",
    statBar: [
      {
        statNumber: "10",
        statName: "activation days",
      },
      {
        statNumber: "2,722",
        statName: "total consumers",
      },
      {
        statNumber: "1,057",
        statName: "total portraits captured",
      },
      {
        statNumber: "312,975",
        statName: "visual impressions",
      },
    ],
    gallery: [
      {
        url: "/project/hidden-portraits/hidden-portraits_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/hidden-portraits/hidden-portraits_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/hidden-portraits/hidden-portraits_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/hidden-portraits/hidden-portraits_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/hidden-portraits/hidden-portraits_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/hidden-portraits/hidden-portraits_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/hidden-portraits/hidden-portraits_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/hidden-portraits/hidden-portraits_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/hidden-portraits/hidden-portraits_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/hidden-portraits/hidden-portraits_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/hidden-portraits/hidden-portraits_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/hidden-portraits/hidden-portraits_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/15.png",
    path: "/project/creative-energy/",
    client: "Dropbox",
    projectName: "Creative Energy",
    type: "physical",
    // featuredImageUrl: "/project/creative-energy/creative-energy_featured.png",
    description:
      "To inspire people to unlock their creative energy and engage them around meaningful issues like human rights, the future, and environmentalism, TH worked with Dropbox to realize an interactive public art installation in the heart SXSW. Over the course of three days, three sets of artists told visual stores around each of the issues, with each day featuring a different topic. The creative community at SXSW was invited to participate, taking a selfie with the artistic rendering of topics they cared about, and receiving Dropbox giveaways branded with the issue at hand to continue the conversation. This dynamic, ever-changing visual conversation engaged over 7,196 SXSW attendees and generated 20,078 visual impressions.",
    statBar: [
      {
        statNumber: "3",
        statName: "activation days",
      },
      {
        statNumber: "7,196",
        statName: "engagements",
      },
      {
        statNumber: "28,078",
        statName: "visual impressions",
      },
    ],
    gallery: [
      {
        url: "/project/creative-energy/creative-energy_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/creative-energy/creative-energy_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/creative-energy/creative-energy_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/creative-energy/creative-energy_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/creative-energy/creative-energy_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/creative-energy/creative-energy_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/creative-energy/creative-energy_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/creative-energy/creative-energy_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/creative-energy/creative-energy_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/creative-energy/creative-energy_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/creative-energy/creative-energy_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/creative-energy/creative-energy_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/16.png",
    path: "/project/bartershop/",
    client: "Harrys",
    projectName: "Bartershop",
    type: "physical",
    // featuredImageUrl: "/project/harrys-bartershop/harrys-bartershop_featured.png",
    description:
      "Working in collaboration with design firm Partners & Spade, TH fabricated and produced an immersive pop-up shop for Harry’s, situated in NYC’s SoHo district. The space was transformed into a modern bathroom environment, where bathrobe-clad retail staff invited shoppers inside to trade in their old razors and shave products for Harry’s equivalents. The pop-up kicked off with a launch event, where press/media attendees were served bathtub gin cocktails, inspired by the brand’s face wash, and personal-sized Domino’s pizzas. A social photo booth captured attendees posing with oversized Harry’s razor props, and on-site embroiderers created individual custom robes as a premium takeaway for guests.",
    statBar: [
      {
        statNumber: "6",
        statName: "activation days",
      },
      {
        statNumber: "1",
        statName: "press/media event",
      },
      {
        statNumber: "176,872",
        statName: "visual impressions",
      },
      {
        statNumber: "515",
        statName: "trades in store",
      },
    ],
    gallery: [
      {
        url: "/project/harrys-bartershop/harrys-bartershop_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/harrys-bartershop/harrys-bartershop_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/harrys-bartershop/harrys-bartershop_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/harrys-bartershop/harrys-bartershop_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/harrys-bartershop/harrys-bartershop_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/harrys-bartershop/harrys-bartershop_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/harrys-bartershop/harrys-bartershop_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/harrys-bartershop/harrys-bartershop_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/harrys-bartershop/harrys-bartershop_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/harrys-bartershop/harrys-bartershop_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/harrys-bartershop/harrys-bartershop_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/harrys-bartershop/harrys-bartershop_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/olly-featured.png",
    path: "/project/olly-winter-wellness/",
    client: "Olly",
    projectName: "Winter Wellness Event",
    type: "physical",
    // featuredImageUrl: "/project/race-to-pcas/race-to-pcas_featured.png",
    description:
      "To highlight OLLY’s holistic approach to wellness and launch the new Immunity supplements, we designed a Winter Wellness Cafe pop up at Central Park, NYC. The Olly Cafe Truck served hot cocoa and matcha beverages, sampled Olly products and featured branded photo-ops. Olly Brand Ambassador, Rebel Wilson, made an appearance at the Cafe to share with press the wellness tips that contributed to her documented weight-loss, including walking. Attendees were then invited to join her on a walk through Central Park, stopping for photo ops and being surprised on the trail with an Olly Hydration Station serving Olly Immunity drinks.",
    gallery: [
      {
        url: "/project/olly-winter-wellness/olly_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/olly-winter-wellness/olly_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/olly-winter-wellness/olly_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/olly-winter-wellness/olly_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/olly-winter-wellness/olly_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/olly-winter-wellness/olly_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/olly-winter-wellness/olly_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/olly-winter-wellness/olly_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/olly-winter-wellness/olly_9.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/olly-winter-wellness/olly_10.png",
        alt: "",
        sizeClass: "twothird",
      }
    ],
  },
  {
    thumbnailUrl: "/physical/18.png",
    path: "/project/strawberry-fields/",
    client: "Wendy's",
    projectName: "Strawberry Fields",
    type: "physical",
    // featuredImageUrl: "/project/strawberry-fields/strawberry-fields_featured.png",
    description:
      "To publicize the launch of Wendy’s new Strawberry Fields Chicken Salad, TH partnered with Ketchum to create the ultimate summer picnic event, welcoming in press and media to the garden-lined rooftop of 620 Loft & Garden in New York for an evening of food, fun and fashion. In true picnic style, guests had the opportunity to participate in vintage lawn games, create their own summer bracelets and enjoy the brand’s new salad while sipping on Wendy’s Strawberry Lemonade. As a digital extension, attendees were encouraged to snap a pic of themselves using the hashtag #NewSaladCollection for a chance to win a Wendy’s-branded bicycle. Molly Sims served as the evening’s host, showcasing her very own Polyvore fashion board, pinned with the hottest fashion trends in line with the salad’s playful summer color palette.",
    statBar: [
      {
        statNumber: "1",
        statName: "activation day",
      },
      {
        statNumber: "65",
        statName: "attendees",
      },
    ],
    gallery: [
      {
        url: "/project/strawberry-fields/strawberry-fields_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/strawberry-fields/strawberry-fields_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/strawberry-fields/strawberry-fields_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/strawberry-fields/strawberry-fields_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/strawberry-fields/strawberry-fields_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/strawberry-fields/strawberry-fields_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/strawberry-fields/strawberry-fields_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/strawberry-fields/strawberry-fields_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/strawberry-fields/strawberry-fields_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/strawberry-fields/strawberry-fields_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/strawberry-fields/strawberry-fields_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/strawberry-fields/strawberry-fields_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/19.png",
    path: "/project/ownit/",
    client: "Special K",
    projectName: "#OWNIT",
    type: "physical",
    // featuredImageUrl: "/project/ownit/ownit_featured.png",
    description:
      "New research commissioned by Special K shows 90 percent of women in the United States question or doubt their food and spend 61 minutes second-guessing their choices—every day. In celebration of Special K’s #OWNIT campaign, TH partnered with Krispr and Special K to produce an activation that encourages woman to combat any self-doubt about their food choices and eat with confidence. Taking over Time Square Plaza, TH built an oversized “Digital Clock” that rotated custom content for one hour and one minute displaying examples of how real women second-guess their food choices. A surprise appearance by Taraji P. Henson drove attendees to #OWNIT and ditch their food doubts with Special K. While on the plaza, guests also had the opportunity to sample Special K and have their photo taken and shared on Special K’s custom Time Square billboard for the day.",
    gallery: [
      {
        url: "/project/ownit/ownit_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/ownit/ownit_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/ownit/ownit_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/ownit/ownit_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/ownit/ownit_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/ownit/ownit_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/ownit/ownit_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/ownit/ownit_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/ownit/ownit_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/ownit/ownit_10.png",
        alt: "",
        sizeClass: "half",
      },
    ],
  },
  
  {
    thumbnailUrl: "/physical/20.png",
    path: "/project/short-lead-media-event/",
    client: "Soap&Glory",
    projectName: "Short Lead Media Event",
    type: "physical",
    // featuredImageUrl: "/project/soap-glory-short-lead/soap-glory-short-lead_featured.png",
    description:
      "TH partnered with MML and Soap & Glory to host an evening of glam and bubbles at La Sirena in New York City in celebration of Soap and Glory’s new product launches. Editors and media gathered to enjoy a variety of exclusive curated experiences including a custom champagne bar with bubbles on tap, oversized vanity with all of the latest Soap & Glory cosmetics, pink tiled shower installation complete with scented bubbles and a bath product display and a custom bathtub photo-op surrounded by suspended bath bombs. Additional pink décor and product displays were featured on each wall of the venue and S&G-inspired passed hors d’oeuvres and spirits were served throughout the night.",
    statBar: [
      {
        statNumber: "9",
        statName: "markets",
      },
      {
        statNumber: "12",
        statName: "activation days",
      },
      {
        statNumber: "1,179",
        statName: "total number of services",
      },
      {
        statNumber: "9,419",
        statName: "product trials",
      },
      {
        statNumber: "4,858,225",
        statName: "visual impressions",
      },
      {
        statNumber: "127,716",
        statName: "social impressions",
      },
    ],
    gallery: [
      {
        url: "/project/soap-glory-short-lead/soap-glory-short-lead_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/soap-glory-short-lead/soap-glory-short-lead_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/soap-glory-short-lead/soap-glory-short-lead_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/soap-glory-short-lead/soap-glory-short-lead_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/soap-glory-short-lead/soap-glory-short-lead_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/soap-glory-short-lead/soap-glory-short-lead_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/soap-glory-short-lead/soap-glory-short-lead_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/soap-glory-short-lead/soap-glory-short-lead_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/soap-glory-short-lead/soap-glory-short-lead_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/soap-glory-short-lead/soap-glory-short-lead_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/soap-glory-short-lead/soap-glory-short-lead_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/soap-glory-short-lead/soap-glory-short-lead_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/21.png",
    path: "/project/grassroots-tour/",
    client: "Peloton",
    projectName: "Grassroots Tour",
    type: "physical",
    // featuredImageUrl: "/project/grassroots-tour/grassroots-tour_featured.png",
    description:
      "TH teamed up with Peloton to showcase their unique, interactive fitness solution direct to consumers by scouting and securing locations for a cross-country mobile tour. The tour stopped by nine locations throughout the U.S. in markets where Peloton does not currently have showrooms, including footprints at larger events including Wanderlust Festival, San Francisco Marathon, Tour of Utah and more. TH handled all venue permitting, tour and security management, and event logistics on-site to bring the at-home rider experience closer to the ever-expanding Peloton community.",
    gallery: [
      {
        url: "/project/grassroots-tour/grassroots-tour_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/grassroots-tour/grassroots-tour_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/grassroots-tour/grassroots-tour_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/grassroots-tour/grassroots-tour_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/grassroots-tour/grassroots-tour_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/grassroots-tour/grassroots-tour_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/grassroots-tour/grassroots-tour_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/grassroots-tour/grassroots-tour_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/grassroots-tour/grassroots-tour_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/grassroots-tour/grassroots-tour_10.png",
        alt: "",
        sizeClass: "half",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/6.png",
    path: "/project/avicii-concert/",
    client: "Ralph Lauren Denim Supply",
    projectName: "Avicii",
    type: "physical",
    // featuredImageUrl: "/project/avicii-concert/avicii-concert_featured.jpg",
    description:
      "To celebrate the genre bending partnership between Avicii and Ralph Lauren Denim Supply, TH hosted a live concert performance where getting a ticket was part of the experience. Collaborating with Macy’s, fans were able to purchase a limited-edition Ralph Lauren Denim Supply shirt to get access to tickets for the special event. Thousands of fans showed up in the RL apparel to support their favorite EDM artist, connecting the brand and cultural moment together. TH produced this energy-infused performance and creatively layered in custom wild postings, guerilla graffiti, and nostalgic signage reminiscent of the glory days of CBGB.",
    statBar: [
      {
        statNumber: "1",
        statName: "activation day",
      },
      {
        statNumber: "3,300",
        statName: "attendees",
      },
      {
        statNumber: "15,000",
        statName: "visual impressions",
      },
      {
        statNumber: "8,000,000",
        statName: "social media impressions",
      },
    ],
    gallery: [
      {
        url: "/project/avicii-concert/avicii-concert_featured-lg.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/avicii-concert/avicii-concert_1.jpg",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/avicii-concert/avicii-concert_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/avicii-concert/avicii-concert_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/avicii-concert/avicii-concert_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/avicii-concert/avicii-concert_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/avicii-concert/avicii-concert_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/avicii-concert/avicii-concert_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/avicii-concert/avicii-concert_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/avicii-concert/avicii-concert_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/avicii-concert/avicii-concert_10.png",
        alt: "",
        sizeClass: "half",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/17.png",
    path: "/project/made-by-you/",
    client: "Converse",
    projectName: "Made By You",
    type: "physical",
    // featuredImageUrl: "/project/made-by-you/made-by-you_featured.png",
    description:
      "Converse came to TH to help animate IRL the “Made by You” campaign celebrating the classic Chuck Taylor as a shoe truly defined by those that wear it. To celebrate the unique wear, tear, and custom additions that Chuck Taylor Converse wearers have been adding for years, TH created an interactive outdoor museum-style installation that highlighted customized shoes from noted designers, athletes, and artists - from Patti Smith to local musicians. The series of thirteen eye-catching lightboxes, placed in Flat Iron Square, featured real shoes from real people that transformed Chucks into their artistic canvas. To celebrate the beauty of a life-time of wear, tear, and love, TH pioneered early-stage Google Cardboard integration to enable consumers to experience the shoes of international icons in virtual reality. This combination of visual storytelling and interactive technology helped emotionally connect guests with Converse and stories of people authentically expressing themselves.",
    statBar: [
      {
        statNumber: "6",
        statName: "activation days",
      },
      {
        statNumber: "8,705",
        statName: "unique engagements",
      },
      {
        statNumber: "1,598,568",
        statName: "visual impressions",
      },
    ],
    gallery: [
      {
        url: "/project/made-by-you/made-by-you_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/made-by-you/made-by-you_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/made-by-you/made-by-you_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/made-by-you/made-by-you_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/made-by-you/made-by-you_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/made-by-you/made-by-you_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/made-by-you/made-by-you_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/made-by-you/made-by-you_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/made-by-you/made-by-you_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/made-by-you/made-by-you_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/made-by-you/made-by-you_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/made-by-you/made-by-you_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/12.png",
    path: "/project/pop-this-not-that/",
    client: "Proactiv",
    projectName: "Pop This, Not That!",
    type: "physical",
    // featuredImageUrl: "/project/pop-this-not-that/pop-this-not-that_featured.png",
    description:
      "To get Gen Z excited about skincare and removing the stigma from acne, TH partnered with Proactiv to design an immersive, interactive, and Instagrammable branded pop-up. “Pop This, Not That” was built around the idea that teens shouldn’t have to pop pimples. Instead, we offered a series of highly engaging and shareable experiences that gave the young audience a fun way to pop all things poppable in a playful space. Poppable bubble walls, balloons, pop art walls, poppable bubble gum, popcorn, and soda pop all came together to provide rich storytelling moments throughout the space. These content creation opportunities enabled both influencers and celebrity partners like Cameron Dallas, Olivia Munn and Maite Perroni to generate buzz and inspired teens to share their “Pop This, Not That” photos, making this event a real content hub for the brand and generated 137,673,088 impressions.",
    statBar: [
      {
        statNumber: "1",
        statName: "activation day",
      },
      {
        statNumber: "464,474,904",
        statName: "media impressions",
      },
      {
        statNumber: "137,673,088",
        statName: "social impressions",
      },
    ],
    gallery: [
      {
        url: "/project/pop-this-not-that/pop-this-not-that_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/pop-this-not-that/pop-this-not-that_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/pop-this-not-that/pop-this-not-that_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/pop-this-not-that/pop-this-not-that_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/pop-this-not-that/pop-this-not-that_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/pop-this-not-that/pop-this-not-that_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/pop-this-not-that/pop-this-not-that_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/pop-this-not-that/pop-this-not-that_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/pop-this-not-that/pop-this-not-that_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/pop-this-not-that/pop-this-not-that_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/pop-this-not-that/pop-this-not-that_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/pop-this-not-that/pop-this-not-that_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/22.png",
    path: "/project/road-trip/",
    client: "Birchbox",
    projectName: "Road Trip",
    type: "physical",
    // featuredImageUrl: "/project/road-trip/road-trip_featured.png",
    description:
      "TH teamed up with Birchbox to launch a first-of-its-kind tour for the brand, bringing the company’s vast array of beauty products and services into a pop-up shop experience. Launching in Atlanta, the Road Trip invited consumers in to browse a curated assortment of makeup, skincare and hair products and purchase full-sized items at both the men and women’s temporary retail stores. A BYOB (Build Your Own Box) station offered customers an engaging way to discover and test new products by choosing up to five samples. On the roof deck of the steel containers, attendees indulged in free services, including haircuts for men and manicures for women. Special guest appearances included the cast of Empire, The Real Housewives of Atlanta’s Cynthia Bailey and D’Angelo Russell of the Los Angeles Lakers.",
    statBar: [
      {
        statNumber: "9",
        statName: "activation days",
      },
      {
        statNumber: "3",
        statName: "markets",
      },
      {
        statNumber: "730,000,000",
        statName: "press/social media impressions",
      },
      {
        statNumber: "607,750",
        statName: "visual impressions",
      },
    ],
    gallery: [
      {
        url: "/project/road-trip/road-trip_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/road-trip/road-trip_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/road-trip/road-trip_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/road-trip/road-trip_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/road-trip/road-trip_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/road-trip/road-trip_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/road-trip/road-trip_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/road-trip/road-trip_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/road-trip/road-trip_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/road-trip/road-trip_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/road-trip/road-trip_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/road-trip/road-trip_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/23.png",
    path: "/project/brow-bus/",
    client: "Benefit",
    projectName: "Brow Bus",
    type: "physical",
    // featuredImageUrl: "/project/brow-bus/brow-bus_featured.png",
    description:
      "TH executed a two-market mobile tour with the goal of driving trial and awareness of Benefit Cosmetics’ signature brow waxing services. Mimicking Benefit Brow Bar salons across the country, the interior of a 1970s Airstream was transformed to facilitate six service stations with the exterior displaying brand creative and a social call-to-action. Influencer talent was brought in to help promote the campaign, including Natalie Suarez from Natalie Off Duty and Bryce Gruber from Luxury Spot. After making multiple stops in New York City to service press/media attendees, the brand allowed its fans to choose the second city, asking its followers to vote for the next location. The final stop? Chicago’s Pioneer Court.",
    statBar: [
      {
        statNumber: "3",
        statName: "activation days",
      },
      {
        statNumber: "755+",
        statName: "@Benefit beauty tags",
      },
      {
        statNumber: "74,880",
        statName: "on-the-road impressions",
      },
      {
        statNumber: "6,000",
        statName: "samples distributed",
      },
      {
        statNumber: "768,625",
        statName: "visual impressions",
      },
    ],
    gallery: [
      {
        url: "/project/brow-bus/brow-bus_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/brow-bus/brow-bus_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/brow-bus/brow-bus_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/brow-bus/brow-bus_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/brow-bus/brow-bus_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/brow-bus/brow-bus_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/brow-bus/brow-bus_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/brow-bus/brow-bus_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/brow-bus/brow-bus_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/brow-bus/brow-bus_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/brow-bus/brow-bus_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/brow-bus/brow-bus_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/24.png",
    path: "/project/road-to-discovery/",
    client: "Plated",
    projectName: "Road To Discovery",
    type: "physical",
    // featuredImageUrl: "/project/road-to-discovery/road-to-discovery_featured.png",
    description:
      "To launch their product to change people’s perceptions about cooking, Plated partnered with TH on a mission to help people across the country discover new ingredients, recipes and techniques that would inspire them to cooking. To connect with new audiences TH created a cross-country tour by way of a retrofitted 1970s Airstream trailer with experiences designed to help them cook more and live better. At stops, guests could get personalized spice and recipe recommendations and see live cooking demonstrations showing how simple and convenient meals at home can be. Plated integrated several partners that reflect the values of quality and discovery such as Mastercard, Pandora, McCormick Spices, S. Pellegrino, Delonghi, Laurel & Wolf, Food & Wine Magazine. Sponsor’s products were woven into the existing footprint, providing engaging touchpoints that amplified the tour experience.",
    statBar: [
      {
        statNumber: "17",
        statName: "activation days",
      },
      {
        statNumber: "9",
        statName: "markets",
      },
      {
        statNumber: "97,340,318",
        statName: "press/media impressions",
      },
      {
        statNumber: "13,400,000",
        statName: "social media impressions",
      },
      {
        statNumber: "16,064",
        statName: "unique engagements",
      },
      {
        statNumber: "3,200,728",
        statName: "visual impressions",
      },
    ],
    gallery: [
      {
        url: "/project/road-to-discovery/road-to-discovery_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/road-to-discovery/road-to-discovery_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/road-to-discovery/road-to-discovery_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/road-to-discovery/road-to-discovery_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/road-to-discovery/road-to-discovery_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/road-to-discovery/road-to-discovery_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/road-to-discovery/road-to-discovery_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/road-to-discovery/road-to-discovery_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/road-to-discovery/road-to-discovery_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/road-to-discovery/road-to-discovery_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/road-to-discovery/road-to-discovery_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/road-to-discovery/road-to-discovery_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/7.png",
    path: "/project/supersonic-lab/",
    client: "Dyson",
    projectName: "Supersonic Lab",
    type: "physical",
    // featuredImageUrl: "/project/supersonic-lab/dyson_supersonic_featured.png",
    description:
      "Getting the only hair dryer in the world that took 103 engineers and stylists four years to create into the hands of people across the country was Dyson’s mission for it’s Supersonic product launch. TH partnered with the iconic British brand to design and engineer a mobile Supersonic Lab experience that would bring Dyson innovation to nine cities across the country. The Lab brought a mobile hair salon experience to the people, executing over 1,179 blow-outs, 9,491 product trials, and generating $29,490 in sales. The ten-stop tour garnered extensive exposure for the brand and its entrance into the personal care market with over 4,858,225 visual impressions. Guest’s Dyson moments were captured through an onsite photo booth and generated over 128,000 social impressions.",
    statBar: [
      {
        statNumber: "9",
        statName: "markets",
      },
      {
        statNumber: "12",
        statName: "activation days",
      },
      {
        statNumber: "1,179",
        statName: "total number of services",
      },
      {
        statNumber: "9,419",
        statName: "product trials",
      },
      {
        statNumber: "4,858,225",
        statName: "visual impressions",
      },
      {
        statNumber: "127,716",
        statName: "social impressions",
      },
    ],
    gallery: [
      {
        url: "/project/supersonic-lab/dyson_supersonic_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/supersonic-lab/dyson_supersonic_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/supersonic-lab/dyson_supersonic_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/supersonic-lab/dyson_supersonic_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/supersonic-lab/dyson_supersonic_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/supersonic-lab/dyson_supersonic_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/supersonic-lab/dyson_supersonic_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/supersonic-lab/dyson_supersonic_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/supersonic-lab/dyson_supersonic_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/supersonic-lab/dyson_supersonic_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/supersonic-lab/dyson_supersonic_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/supersonic-lab/dyson_supersonic_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/2.png",
    path: "/project/apollo-50th-anniversary/",
    client: "IBM",
    projectName: "Apollo 50th Anniversary",
    type: "physical",
    // featuredImageUrl: "/project/apollo-50th/apollo-50th_featured.jpg",
    description:
      "Celebrating the 50th anniversary Apollo 11 moon landing, TH brought to life an IBM brand moment that surprised thousands of people passing through the Oculus with a digitally interactive simulated moonwalk experience. Powered by a combination of AI, responsive digital screens and flooring, guests could walk on the moon and create a short video of themselves as astronauts on the moon to share creating over 900,000 impressions. This installation highlighted IBM’s role both in the original mission and down to today with dynamic graphic displays and immersive vignettes. TH designed and installed living room sets throughout the decades, replicating experiences where families would gather to hear the news on space innovation. The authentic decor elements offered interactive moments like playable Atari for the 70’s vignette or floating art pieces for a future-inspired vignette. More than mere photo moments, the sets generated memories and emotional connections with all who participated.",
    gallery: [
      {
        url: "/project/apollo-50th/apollo-50th_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/apollo-50th/apollo-50th_2.jpg",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/apollo-50th/apollo-50th_3.jpg",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/apollo-50th/apollo-50th_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/apollo-50th/apollo-50th_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/apollo-50th/apollo-50th_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/apollo-50th/apollo-50th_7.jpg",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/apollo-50th/apollo-50th_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/apollo-50th/apollo-50th_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/apollo-50th/apollo-50th_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/apollo-50th/apollo-50th_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/apollo-50th/apollo-50th_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
  {
    thumbnailUrl: "/physical/3.png",
    path: "/project/6093/",
    client: "The Macallan",
    projectName: "New Distillery + Edition No.4 Press Event",
    type: "physical",
    // featuredImageUrl: "/project/macallan-distillery/macallan-distillery_featured.png",
    description:
      "To announce the new breathtaking Macallan Speyside distillery and No. 4 edition bottle, TH partnered with M booth to craft an immersive educational campaign that connected press/media and consumers to the heart of Scotland. The premiere for the experience was a private press event in Brooklyn, set in a historic venue that naturally matched the spirit of The Macallan. Inside guests were taken through a sensory-filled journey that featured recreated vignettes from The Macallan estate, sketches and blueprints, and an eye-catching 4D estate guide that let guests move inside of a 360 projection cube. All decor and furniture echoed the brand’s Scottish heritage while acknowledging their new modern direction bringing their brand story to life. To expand our reach to consumers, TH executed the event in NYC’s iconic Grand Central station where passersby could experience the 4D estate guide, pose in front of a branded living wall, and sample product from experts.",
    gallery: [
      {
        url: "/project/macallan-distillery/macallan-distillery_1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/project/macallan-distillery/macallan-distillery_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/macallan-distillery/macallan-distillery_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/macallan-distillery/macallan-distillery_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/macallan-distillery/macallan-distillery_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/macallan-distillery/macallan-distillery_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/macallan-distillery/macallan-distillery_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/project/macallan-distillery/macallan-distillery_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/macallan-distillery/macallan-distillery_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/macallan-distillery/macallan-distillery_10.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/project/macallan-distillery/macallan-distillery_11.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/project/macallan-distillery/macallan-distillery_12.png",
        alt: "",
        sizeClass: "twothird",
      },
    ],
  },
];

export default allProjects;
